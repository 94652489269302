
import { IonGrid, IonRow, IonCol, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "../store";
import { DateTime } from "luxon";
import axios from "axios";

export default defineComponent({
  name: "Invitation",
  components: {
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
  },
  data: () => {
    return {
      store: useStore(),
      party: null,
    };
  },
  methods: {
    async getInvitation() {
      try {
        const url: string = encodeURIComponent(
          this.$route.params.code.toString()
        );
        // Call API
        const res = await axios.get(
          this.store.state.apiUrl + "/api/invitation/" + url
        );
        this.party = res.data;
      } catch (error) {
        console.log(error.response.data);
      }
    },
    formatDate(date: string): any {
      // 2021-08-28T14:30:52.688+01:00
      return DateTime.fromISO(date).toFormat("dd LLL yyyy - HH:mm");
    },
  },
  mounted() {
    this.getInvitation();
  },
});
